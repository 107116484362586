import React from "react";
import { createRoot } from "react-dom/client";
import { Amplify } from "aws-amplify";
import { BrowserRouter } from "react-router-dom";
import { initSentry } from "libs/error";

import "styles/index.css";

import "i18n";
import { config } from "config";

import App from "App";
import reportWebVitals from "reportWebVitals";

const container: HTMLElement = document.getElementById("root")!;
const root = createRoot(container);

initSentry();

Amplify.configure({
  Auth: {
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    bucket: config.s3.BUCKET
  },
  API: {
    endpoints: [
      {
        name: "activations",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "decryption",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
});

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
