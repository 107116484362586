export const config = {
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_ENV: process.env.REACT_APP_SENTRY_ENV,
  cognito: {
    APP_CLIENT_ID: process.env.REACT_APP_AWS_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    REGION: "us-east-1",
    USER_POOL_ID: process.env.REACT_APP_AWS_USER_POOL_ID
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: process.env.REACT_APP_AWS_API_GATEWAY
  },
  s3: {
    REGION: "us-east-1",
    BUCKET: process.env.REACT_APP_AWS_BUCKET
  }
};
