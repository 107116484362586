import { Route, Routes } from "react-router-dom";
import Library from "containers/Library";
import Book from "containers/Book";
import Details from "containers/Details";
import Login from "containers/Login";
import Forgot from "containers/Login/Forgot";
import Confirm from "containers/Login/Confirm";
import Account from "containers/Account";
import ChangePassword from "containers/Account/ChangePassword";
import ChangeEmail from "containers/Account/ChangeEmail";
import VerifyEmail from "containers/Account/VerifyEmail";
import MultiFactorAuth from "containers/Login/MultiFactorAuth";
import NotFound from "containers/NotFound";
import AuthenticatedRoute from "components/AuthenticatedRoute";
import UnauthenticatedRoute from "components/UnauthenticatedRoute";

export default function Routing() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthenticatedRoute>
            <Library />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/book/:id"
        element={
          <AuthenticatedRoute>
            <Book />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/details/:id"
        element={
          <AuthenticatedRoute>
            <Details />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/login"
        element={
          <UnauthenticatedRoute>
            <Login />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path="/login/mfa"
        element={
          <UnauthenticatedRoute>
            <MultiFactorAuth />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path="/login/forgot"
        element={
          <UnauthenticatedRoute>
            <Forgot />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path="/login/confirm"
        element={
          <UnauthenticatedRoute>
            <Confirm />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path="/account"
        element={
          <AuthenticatedRoute>
            <Account />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/account/change-password"
        element={
          <AuthenticatedRoute>
            <ChangePassword />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/account/change-email"
        element={
          <AuthenticatedRoute>
            <ChangeEmail />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/account/verify"
        element={
          <AuthenticatedRoute>
            <VerifyEmail />
          </AuthenticatedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
