import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormFields } from "libs/fields";
import { onError } from "libs/error";
import { Auth } from "@aws-amplify/auth";
import { PasswordValidation, Validate } from "components/PasswordValidation";
import { ClickButton, SubmitButton, PasswordInput, TextInput } from "components/FormElements";
import Header from "components/Header";
import Footer from "components/Footer";
import Modal from "components/Modal";

export default function Confirm() {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(<span></span>);

  const [fields, handleFieldChange] = useFormFields({
    code: "",
    password: ""
  });

  useEffect(() => {
    document.title = t("local.login.forgotpass.title.resetsuccess") + " - " + t("global.system.name");
    if (process.env.REACT_APP_VERSION !== "OFFICIAL") {
      document.title += " | " + process.env.REACT_APP_VERSION;
    }
  });

  function validateForm() {
    return Validate(fields.password).isValid && fields.code.length === 6;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.forgotPasswordSubmit(location.state.email, fields.code, fields.password);
      setIsLoading(false);
      setShowModal(true);
    } catch (e) {
      const errorHandler = onError(e);
      setErrorMessage(errorHandler);
      setIsLoading(false);
    }
  }

  async function resendConfirmationCode(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.resendSignUp(location.state.email);
      setIsLoading(false);
    } catch (e) {
      const errorHandler = onError(e);
      setErrorMessage(errorHandler);
      setIsLoading(false);
    }
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow bg-ct-lightgrey px-4 md:px-0">
        <h2 className="text-3xl text-black text-center pt-20 mb-2.5">{t("local.login.forgotpass.title.main")}</h2>
        <p className="text-base text-black text-center mb-6">{t("local.login.forgotpass.subtitle.entercode")}</p>
        <form className="container mx-auto max-w-md flex flex-col justify-center space-y-4 pb-20" onSubmit={handleSubmit}>
          <TextInput handleChange={handleFieldChange} name="code" value={fields.code} label={t("global.label.confirmationcode")} />
          <PasswordInput
            handleChange={handleFieldChange}
            handleShow={() => setShowPassword(!showPassword)}
            visible={showPassword}
            value={fields.password}
            label={t("global.label.newpassword")}
          />
          <PasswordValidation password={fields.password} />
          <SubmitButton disabled={!validateForm() || isLoading} loading={isLoading} label={t("global.button.continue")} />
          <p className="boutton bg-gray-100 inline-flex text-sm border border-ct-grey text-gray-800 px-4 py-3">
            <i className="icon-drawer"></i>
            <span className="ml-2">{t("global.label.checkemailforcode")}</span>
          </p>
          <ClickButton handleClick={resendConfirmationCode} disabled={isLoading} loading={isLoading} label={t("global.button.sendnewcode")} />
          {errorMessage}
        </form>
      </main>
      {showModal && (
        <Modal
          title={t("local.login.forgotpass.label.resetsuccess")}
          subtitle={t("local.login.forgotpass.subtitle.reset")}
          handleClick={() => navigate("/login")}
          clickLabel={t("global.button.continue")}
        />
      )}
      <Footer />
    </div>
  );
}
