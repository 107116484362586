import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "context/AppContext";
import { useFormFields } from "libs/fields";
import { onError } from "libs/error";
import { Auth } from "@aws-amplify/auth";
import { SubmitButton, PasswordInput } from "components/FormElements";
import { PasswordValidation, Validate } from "components/PasswordValidation";
import Header from "components/Header";
import Footer from "components/Footer";
import Modal from "components/Modal";

export default function ChangePassword() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { user } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(<span></span>);
  const [showCurrent, setShowCurrent] = useState(false);
  const [showNew, setShowNew] = useState(false);

  const [fields, handleFieldChange] = useFormFields({
    oldPassword: "",
    newPassword: ""
  });

  useEffect(() => {
    document.title = t("local.account.changepass.title.page") + " - " + t("global.system.name");
    if (process.env.REACT_APP_VERSION !== "OFFICIAL") {
      document.title += " | " + process.env.REACT_APP_VERSION;
    }
  });

  function validateForm() {
    return Validate(fields.newPassword).isValid && fields.oldPassword.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.changePassword(user, fields.oldPassword, fields.newPassword);
      setIsLoading(false);
      setShowModal(true);
    } catch (e) {
      const errorHandler = onError(e);
      setErrorMessage(errorHandler);
      setIsLoading(false);
    }
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow bg-ct-lightgrey px-4 md:px-0">
        <h2 className="text-3xl text-black text-center pt-20 mb-2.5">{t("local.account.changepass.title.main")}</h2>
        <p className="text-base text-black text-center mb-6">{t("local.account.changepass.subtitle.main")}</p>
        <form className="container mx-auto max-w-md flex flex-col justify-center space-y-4 pb-20" onSubmit={handleSubmit}>
          <PasswordInput
            id="oldPassword"
            handleChange={handleFieldChange}
            handleShow={() => setShowCurrent(!showCurrent)}
            visible={showCurrent}
            value={fields.oldPassword}
            label={t("global.label.currentpassword")}
          />
          <PasswordInput
            id="newPassword"
            handleChange={handleFieldChange}
            handleShow={() => setShowNew(!showNew)}
            visible={showNew}
            value={fields.newPassword}
            label={t("global.label.newpassword")}
          />
          <PasswordValidation password={fields.newPassword} />
          <SubmitButton disabled={!validateForm() || isLoading} loading={isLoading} label={t("global.button.confirm")} />
          <p className="">
            <Link to="/account" className="transition text-ct-orange hover:text-black">
              {t("global.link.returntoaccount")}
            </Link>
          </p>
          {errorMessage}
        </form>
      </main>
      {showModal && (
        <Modal
          title={t("local.account.changepass.title.success")}
          subtitle={t("local.account.changepass.subtitle.success")}
          handleClick={() => navigate("/account")}
          clickLabel={t("global.button.continue")}
        />
      )}
      <Footer />
    </div>
  );
}
