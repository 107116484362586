import { API, Auth } from "aws-amplify";
import { IEpubOcfResourceProvider } from "@colibrio/colibrio-reader-framework/colibrio-core-publication-epub";

export default class AES256gcmEncryptionMethod {
  resourceProvider: any;
  publication: any;
  ecdh: any;
  symmKey: any;
  encryptUtilsApiUrl: any;
  fileName: string;
  userName: string;

  constructor(ocfContainer: IEpubOcfResourceProvider, _fileName: string, _username: string) {
    this.resourceProvider = ocfContainer;
    this.publication = null;
    this.ecdh = null;
    this.symmKey = null;
    this.fileName = _fileName;
    this.userName = _username;
  }

  getName() {
    return "http://www.w3.org/2009/xmlenc11#aes256-gcm";
  }

  hexify(s: any) {
    let r = [];
    for (let i = 0; i < s.length - 1; i += 2) {
      r.push(String.fromCharCode(parseInt(s.charAt(i) + s.charAt(i + 1), 16)));
    }
    return r.join("");
  }

  base64ToArrayBuffer(base64: any) {
    var binary_string = this.hexify(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  async decrypt(abContent: Uint8Array) {
    let user = await Auth.currentAuthenticatedUser();
    const { attributes } = user;
    let userEthAddr = attributes["custom:blockchain_address"];
    let email = attributes.email;
    let symmKey;

    if (this.symmKey === null) {
      this.symmKey = await this.getCipherKey(email, this.fileName, userEthAddr);
    }
    symmKey = this.symmKey;

    let content = abContent;
    let decrypted = await this.decryptAES256GCM(content, symmKey);

    return decrypted;
  }

  async getCipherKey(userName: string, fileName: string, userEthAddr: any) {
    let xhrResponse = await this.getRemoteEncryptionKey(userName, fileName, userEthAddr);
    //let bufDecrypted = Buffer.from((xhrResponse as any).s, "hex");

    return xhrResponse.s.toString();
  }

  async decryptAES256GCM(content: any, encKey: any) {
    const IV_LENGTH = 12;
    let iv = content.subarray(0, IV_LENGTH);
    let encryptedEpub = content.subarray(IV_LENGTH);
    let aesKey = this.base64ToArrayBuffer(encKey);
    let finalKey = await crypto.subtle.importKey("raw", aesKey, "AES-GCM", true, ["encrypt", "decrypt"]);
    let decipher = await crypto.subtle.decrypt({ name: "AES-GCM", iv }, finalKey, encryptedEpub);

    return decipher;
  }

  async getRemoteEncryptionKey(username: string, filename: string, address: any): Promise<any> {
    const params = {
      b: "blah",
      u: username,
      k: filename,
      h: address
    };

    const response = await API.post("decryption", "/reader/utils", {
      body: params
    });

    return response;
  }
}
