import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export default i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    returnNull: false,
    supportedLngs: ["fr", "en"],
    nonExplicitSupportedLngs: true,
    load: "languageOnly",
    fallbackLng: ["fr", "en"],
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    }
  });
