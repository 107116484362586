import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { bookFromOpds, epubHeader } from "libs/files";
//import { useTranslation } from "react-i18next";
import Reader from "components/Reader";
import ReaderApi from "colibrio/ReaderApi";
import ReaderNavigation from "components/ReaderNavigation";
import { loadBookSettings } from "libs/files";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default function Book() {
  const { id } = useParams();
  //const { t } = useTranslation();
  const [api, setApi] = useState<ReaderApi | null>(null);

  const [book, setBook] = useState<any>(null);
  const [settings, setSettings] = useState<any>(null);

  useEffect(() => {
    async function getBook() {
      const currentUser = await Auth.currentUserInfo();

      const book = bookFromOpds(id!);
      const headers = await epubHeader(book.link[1].href, currentUser.attributes["custom:blockchain_address"]);
      const settings = await loadBookSettings(book.link[1].href, currentUser.attributes["custom:blockchain_address"]);
      const newReaderApi = await ReaderApi.loadPublicationByUrl(currentUser.attributes["custom:blockchain_address"], book.link[1].href, book.link[1].href, headers.ContentLength!);

      if (api) {
        api.destroy();
      }
      setApi(newReaderApi);
      setBook(book);
      setSettings(settings);
      document.title = book.title;
    }
    getBook();
    /*
    document.title = t("local.book.title.page") + " - " + book.title + " - " + t("global.system.name");
    if (process.env.REACT_APP_VERSION !== "OFFICIAL") {
      document.title += " | " + process.env.REACT_APP_VERSION;
    }
    */
  }, [id]);

  return (
    <div className="">
      {api !== null ? (
        <main style={{ height: `${getWindowSize().innerHeight}px` }} className={`flex flex-col pb-safe`}>
          <Reader readerApi={api} settings={settings} />
          <ReaderNavigation readerApi={api} settings={settings} bookFile={book.link[1].href} />
        </main>
      ) : null}
    </div>
  );
}
