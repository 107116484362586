import { useState, useEffect, Fragment, Suspense } from "react";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "components/ErrorBoundary";
import { AppContext } from "context/AppContext";
import Routes from "Routes";
import { onError } from "libs/error";
import { useNavigate } from "react-router-dom";

import { CognitoUserSession, CognitoUser } from "amazon-cognito-identity-js";

function App() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);
  const [isAuthenticated, userHasAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [session, setSession] = useState<CognitoUserSession | null>(null);

  useEffect(() => {
    async function onLoad() {
      try {
        const currentSession = await Auth.currentSession();
        setSession(currentSession);
        const currentUser = await Auth.currentAuthenticatedUser({
          bypassCache: true
        });
        setUser(currentUser);

        if (currentUser.attributes["custom:preferred_language"]) {
          i18n.changeLanguage(currentUser.attributes["custom:preferred_language"]);
        } else if (!currentUser.attributes["custom:blockchain_address"]) {
          navigate("/signup/blockchain");
        }

        userHasAuthenticated(true);
      } catch (e) {
        if (e !== "No current user") {
          onError(e);
        }
      }
      setIsAuthenticating(false);
    }

    onLoad();
  }, [isAuthenticated, i18n, navigate]);

  return (
    <Fragment>
      {!isAuthenticating && (
        <ErrorBoundary>
          <Suspense>
            <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, user, setUser, session, setSession }}>
              <Routes />
            </AppContext.Provider>
          </Suspense>
        </ErrorBoundary>
      )}
    </Fragment>
  );
}

export default App;
