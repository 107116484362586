import Logo from "components/Logos";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ConsentPopup from "./OptOut";

const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <footer className="flex-shrink-0">
      <div className=" bg-black ">
        <div className="container mx-auto grid grid-cols-1 justify-items-center gap-y-16 px-4 py-24 text-white md:grid-cols-3 md:gap-8">
          <div className="w-2/3 md:w-auto">
            <h2 className="uppercase text-xl mb-6">{t("local.footer.section.contactus")}</h2>
            <ul>
              <li className="mb-2.5">
                <i className="icon-envelope mr-4"></i>
                <a className="transition text-base hover:text-ct-orange" href={`mailto:${process.env.REACT_APP_SITE_EMAIL_CONTACT}`}>
                  {process.env.REACT_APP_SITE_EMAIL_CONTACT}
                </a>
              </li>
              <li>
                <i className="icon-phone mr-4"></i>
                <a className="transition text-base hover:text-ct-orange" href={`tel:+${process.env.REACT_APP_SITE_PHONE}`}>
                  {process.env.REACT_APP_SITE_PHONE_FORMATTED}
                </a>
              </li>
            </ul>
          </div>
          <div className="w-2/3 md:w-auto">
            <h2 className="uppercase text-xl  mb-6">{t("local.footer.section.links")}</h2>
            <ul>
              <li className="mb-2.5">
                <NavLink to={"/"} className="transition text-base hover:text-ct-orange">
                  {t("global.link.home")}
                </NavLink>
              </li>
              <li className="mb-2.5">
                <a href={process.env.REACT_APP_URL_SITE_COMPANY!} className="transition text-base hover:text-ct-orange">
                  {t("global.link.website")}
                </a>
              </li>
              <li className="mb-2.5">
                <a href={process.env.REACT_APP_URL_PAGE_NFT as string} className="transition text-base hover:text-cc-customer">
                  {t("global.link.shop")}
                </a>
              </li>
              <li className="mb-2.5">
                <a href={process.env.REACT_APP_URL_SITE_REDEEM as string} className="transition text-base hover:text-ct-orange">
                  {t("global.link.redeem")}
                </a>
              </li>
              <li className="mb-2.5">
                <a href={process.env.REACT_APP_URL_PAGE_FAQ!} className="transition text-base hover:text-ct-orange">
                  {t("global.link.faq")}
                </a>
              </li>
              <li className="mb-2.5">
                <a href={process.env.REACT_APP_URL_PAGE_CONTACT!} className="transition text-base hover:text-ct-orange">
                  {t("global.link.contactus")}
                </a>
              </li>
            </ul>
          </div>
          <div className="mx-auto md:mx-0">
            <a href={process.env.REACT_APP_URL_SITE_SCENAREX as string}>
              <Logo
                logo="PoweredByBookchain"
                language={i18n.resolvedLanguage!}
                imgClass="transition ease-in-out delay-150 w-72 hover:scale-110"
                altText={t("local.footer.logo.poweredbybookchain")}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="bg-transparent text-black text-shadow py-5 px-4 md:px-0">
        <div className="container mx-auto lg:flex items-center justify-between">
          <p className="text-sm text-center lg:text-left">
            {t("local.footer.copyright.company")}
            <span className="mx-1">|</span>
            <a href={process.env.REACT_APP_URL_PAGE_TERMSOFSERVICE as string} className="transition hover:text-white">
              {t("global.link.terms")}
            </a>
            <span className="mx-1">|</span>
            <a href={process.env.REACT_APP_URL_PAGE_PRIVACYPOLICY as string} className="transition hover:text-white">
              {t("global.link.privacy")}
            </a>
            <span className="mx-1">|</span>
            <a href={process.env.REACT_APP_URL_PAGE_DATAPROTECTION as string} className="transition hover:text-white">
              {t("global.link.dataprotection")}
            </a>
          </p>
          <div className="space-x-4 mt-2 text-center lg:text-left">
            <a href={process.env.REACT_APP_URL_SOCIAL_TWITTER as string} className="transition hover:text-white">
              <i className="icon-social-twitter"></i>
            </a>
            <a href={process.env.REACT_APP_URL_SOCIAL_FACEBOOK as string} className="transition hover:text-white">
              <i className="icon-social-facebook"></i>
            </a>
            <a href={process.env.REACT_APP_URL_SOCIAL_INSTAGRAM as string} className="transition hover:text-white">
              <i className="icon-social-instagram"></i>
            </a>
          </div>
        </div>
      </div>
      <ConsentPopup />
    </footer>
  );
};

export default Footer;
