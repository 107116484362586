import { useEffect, useState } from "react";
import placeholder from "images/image-placeholder.svg";

export default function ProgressiveImage({ src, alt, classes }: { src: string; alt: string; classes?: string }) {
  const [imgSrc, setImgSrc] = useState(placeholder || src);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  const loaderClass = placeholder && imgSrc === placeholder ? "blur-md" : "transition duration-700 blur-none";

  return <img className={[classes, loaderClass].join(" ")} src={imgSrc} alt={alt} />;
}
