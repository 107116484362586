import { ClickButton } from "components/FormElements";

export default function Modal({
  title,
  subtitle,
  clickLabel,
  cancelLabel,
  handleClick,
  handleCancel
}: {
  title: string;
  subtitle: string;
  clickLabel: string;
  cancelLabel: string | null;
  handleClick: any;
  handleCancel: () => void;
}) {
  return (
    <div className="fixed flex justify-center items-center inset-0 overflow-y-auto z-50 h-full w-full bg-black" id="modal">
      <div className="flex flex-col justify-center text-white">
        <h1 className="text-3xl text-center mb-3">{title}</h1>
        <p className="whitespace-pre-line text-center">{subtitle}</p>
        <div className="grow-0 flex justify-center mt-4">
          <ClickButton handleClick={handleClick} label={clickLabel} colour="bg-ct-orange" disabled={false} id={""} loading={false} />
        </div>
        <div className="grow-0 flex justify-center mt-4">
          {cancelLabel && <ClickButton handleClick={handleCancel} label={cancelLabel} disabled={false} id={""} loading={false} colour={""} />}
        </div>
      </div>
    </div>
  );
}
