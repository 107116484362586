import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { QRCodeSVG } from "qrcode.react";
import { useAppContext } from "context/AppContext";
import { useFormFields } from "libs/fields";
import { onError } from "libs/error";
import { TextInput, SubmitButton, ClickButton } from "components/FormElements";

export default function SetupMFA({ handleHide, returnStatus }) {
  const { t } = useTranslation();

  const { user } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(<span></span>);
  const [code, setQRcode] = useState({});

  useEffect(() => {
    async function generateQRcode() {
      const qrCode = await Auth.setupTOTP(user);
      const totpString = `otpauth://totp/AWSCognito:${user.username}?secret=${qrCode}&issuer=ComicTags`;
      setQRcode({ code: qrCode, totp: totpString });
    }

    generateQRcode();
  }, [user]);

  const [fields, handleFieldChange] = useFormFields({
    validation: ""
  });

  function validateForm() {
    return fields.validation.length === 6;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.verifyTotpToken(user, fields.validation);
      await Auth.setPreferredMFA(user, "TOTP");
      setIsLoading(false);
      returnStatus(true);
      handleHide();
    } catch (e) {
      const errorHandler = onError(e);
      setErrorMessage(errorHandler);
      setIsLoading(false);
    }
  }

  return (
    <div className="fixed flex justify-center items-center inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative mx-auto grid p-5 border max-w-full md:max-w-fit shadow-lg bg-white">
        <h2 className="text-3xl text-black text-center mb-2.5">{t("local.account.2fa.title.configureapp")}</h2>
        <h3 className="text-xl">{t("local.account.2fa.subtitle.enrollment1")}</h3>
        <div className="md:flex">
          <ol className="max-w-lg">
            <li>{t("local.account.2fa.instruction.1")}</li>
            <li>
              <span>{t("local.account.2fa.instruction.2")}&nbsp;</span>
              <code className="inline-block break-all text-sm p-0.5 bg-ct-lightgrey">{code.code}</code>
            </li>
            <li>{t("local.account.2fa.instruction.3")}</li>
            <li>{t("local.account.2fa.instruction.4")}</li>
          </ol>
          <QRCodeSVG value={code.totp} className="block" />
        </div>
        <form className="flex flex-col justify-center space-y-4 mt-5" onSubmit={handleSubmit}>
          <TextInput handleChange={handleFieldChange} name="validation" value={fields.validation} label={t("global.label.authenticationcode")} />
          <SubmitButton disabled={!validateForm() || isLoading} loading={isLoading} label={t("global.button.validate")} />
          <ClickButton handleClick={handleHide} label={t("global.button.cancel")} />
          {errorMessage}
        </form>
      </div>
    </div>
  );
}
