import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "context/AppContext";
import { useFormFields } from "libs/fields";
import { onError } from "libs/error";
import { Auth } from "aws-amplify";
import { SubmitButton, TextInput } from "components/FormElements";
import Header from "components/Header";
import Footer from "components/Footer";

export default function MultiFactorAuth() {
  const { t } = useTranslation();
  const { userHasAuthenticated, session, setSession } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [fields, handleFieldChange] = useFormFields({
    code: ""
  });
  
  useEffect(() => {
    document.title = t("local.login.title.page") + " - " + t("global.system.name");
    if (process.env.REACT_APP_VERSION !== "OFFICIAL") {
      document.title += " | " + process.env.REACT_APP_VERSION;
    }
  });

  function validateForm() {
    return fields.code.length === 6;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const updatedSession = await Auth.confirmSignIn(session, fields.code, session.challengeName);
      setSession(updatedSession);
      setIsLoading(false);
      userHasAuthenticated(true);
    } catch (e) {
      const errorHandler = onError(e);
      setErrorMessage(errorHandler);
      setIsLoading(false);
    }
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow bg-ct-lightgrey px-4 md:px-0">
        <h2 className="text-3xl text-black text-center pt-20 mb-2.5">{t("local.login.title.opensession")}</h2>
        <p className="text-base text-black text-center mb-6">{t("local.account.subtitle.2fa")}</p>
        <form className="container mx-auto max-w-md flex flex-col justify-center space-y-4 pb-20" onSubmit={handleSubmit}>
          <TextInput handleChange={handleFieldChange} name="code" value={fields.code} label={t("global.label.authenticationcode")} />
          <SubmitButton disabled={!validateForm() || isLoading} loading={isLoading} label={t("global.button.continue")} />
          {errorMessage}
        </form>
      </main>
      <Footer />
    </div>
  );
}
