import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ClickButton } from "./FormElements";

declare global {
  interface Window {
    gaOptout: any;
  }
}

const ConsentPopup = () => {
  const { t } = useTranslation();

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const consent = document.cookie.split("; ").find((row) => row.startsWith("consent="));
    if (!consent) {
      setShowPopup(true);
    }
  }, []);

  const handleReject = () => {
    document.cookie = "consent=rejected; expires=365d; path=/";
    //window.gaOptout();
    setShowPopup(false);
  };

  const handleAccept = () => {
    document.cookie = "consent=accepted; expires=365d; path=/"; // Expires in 365 days

    setShowPopup(false);
  };

  return (
    <>
    {showPopup &&
      <div className="bottom-0 fixed border-t w-full bg-white p-4 shadow-md">
        <div className="gdpr-popup-content container mx-auto">
          <p>{t("global.text.consent_1")}</p>
          <p className="mb-4">
            {t("global.text.consent_2")}{" "}
            <a href={process.env.REACT_APP_URL_PAGE_PRIVACYPOLICY!} className="text-ct-orange">
              {t("global.link.privacy")}
            </a>
            .
          </p>
          <div className="gdpr-popup-actions grid grid-cols-2 gap-4 w-1/3">
            <ClickButton handleClick={handleReject} id="reject" label={t("global.button.refuse")} disabled={false} loading={false} colour="bg-black" />
            <ClickButton handleClick={handleAccept} id="reject" label={t("global.button.accept")} disabled={false} loading={false}  colour="bg-ct-orange" />
          </div>
        </div>
      </div>
    }
    </>
  );
};

export default ConsentPopup;
