import { API, Auth } from "aws-amplify";
import AWS, { AWSError } from "aws-sdk";
import { GetObjectOutput } from "aws-sdk/clients/s3";
import { config } from "config";

type paypalMerchantOnboardingType = {
  email: string;
  returnUrl: string;
  returnUrlDescription: string;
  countryCode: string;
  locale: string;
};

export default class HttpRequest {
  static async getHash() {
    let user = await Auth.currentUserCredentials();
    let hashData = `${user.identityId}`;
    //let hash = crypto.createHash("sha256").update(hashData, "utf8").digest("hex");
    let hashBuffer = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(hashData));
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    return hashHex;
  }

  static sendRangeRequest(username: string, fileName: string, start: number, end: number) {
    return new Promise((resolve, reject) => {
      Auth.currentCredentials().then((token) => {
        let s3 = new AWS.S3({
          credentials: Auth.essentialCredentials(token),
          signatureVersion: "v2"
        });
        let params = {
          Bucket: config.s3.BUCKET!,
          Key: "protected/" + username + "/" + fileName,
          Range: `bytes=${start}-${end}`
        };

        try {
          s3.getObject(params, function (err: AWSError, data: GetObjectOutput) {
            if (err) {
              reject("Not working");
            } else {
              resolve(data.Body);
            }
          });
        } catch (e) {
          throw new Error("Error trying to get chunk of data of " + fileName + " from: " + start + " to" + end + " with credentials: " + token);
        }
      });
    });
  }

  static async paypalMerchantOnboarding(data: paypalMerchantOnboardingType) {
    try {
      let response: Promise<any> = await API.post("paypal", "/merchantOnboarding", {
        body: {
          ...data
        }
      });
      return response;
    } catch (error) {
      //TOFIX: What action should be done here rather than console logging?
      console.error(error);
    }
  }

  static async getPaypalApiReponse() {
    try {
      const response: Promise<any> = await API.get("paypal", "/merchantStatus", {});
      return response;
    } catch (error) {
      //TOFIX: What action should be done here rather than console logging?
      console.error(error);
    }
  }

  static async postPaypalApiReponse() {
    try {
      const response: Promise<any> = await API.post("paypal", "/merchantUnlink", {});
      return response;
    } catch (error) {
      //TOFIX: What action should be done here rather than console logging?
      console.error(error);
    }
  }

  static async listNFT(id: string, price: string) {
    try {
      const response: Promise<any> = await API.post("activations", `/activations/list/${id}`, {
        body: {
          price: price
        }
      });
      return response;
    } catch (error) {
      //TOFIX: What action should be done here rather than console logging?
      if (error instanceof Error) {
        console.log(error.message);
      } else {
        console.log("Unexpected error", error);
      }
    }
  }

  static async fetchActivation(id: string) {
    try {
      const response: Promise<any> = await API.get("ActivationApi", id, {});
      return response;
    } catch (error) {
      //TOFIX: What action should be done here rather than console logging?
      if (error instanceof Error) {
        console.log(error.message);
      } else {
        console.log("Unexpected error", error);
      }
    }
  }
}
