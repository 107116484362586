import common_en from "images/en/Rarity-Common.png";
import common_fr from "images/fr/Rarity-Common.png";
import core_en from "images/en/Rarity-Core.png";
import core_fr from "images/fr/Rarity-Core.png";
import epic_en from "images/en/Rarity-Epic.png";
import epic_fr from "images/fr/Rarity-Epic.png";
import legendary_en from "images/en/Rarity-Legendary.png";
import legendary_fr from "images/fr/Rarity-Legendary.png";
import pressworks_en from "images/en/Rarity-Pressworks.png";
import pressworks_fr from "images/fr/Rarity-Pressworks.png";
import rare_en from "images/en/Rarity-Rare.png";
import rare_fr from "images/fr/Rarity-Rare.png";

type RarityLabels = {
  en: string;
  fr: string;
};

type Source = {
  Common: RarityLabels;
  Core: RarityLabels;
  Epic: RarityLabels;
  Legendary: RarityLabels;
  Pressworks: RarityLabels;
  Rare: RarityLabels;
};

export default function Rarity({ rarity, language, size, valign }: { rarity: string; language: string; size: string; valign?: string }) {
  const source: Source = {
    Common: {
      en: common_en,
      fr: common_fr
    },
    Core: {
      en: core_en,
      fr: core_fr
    },
    Epic: {
      en: epic_en,
      fr: epic_fr
    },
    Legendary: {
      en: legendary_en,
      fr: legendary_fr
    },
    Pressworks: {
      en: pressworks_en,
      fr: pressworks_fr
    },
    Rare: {
      en: rare_en,
      fr: rare_fr
    }
  };

  return (
    <img
      alt={source[rarity as keyof typeof source][language as keyof typeof Rarity]}
      className={`rounded-sm inline ${valign === "" ? "" : valign} ${size === "small" ? "max-h-4 w-auto" : ""}`}
      src={source[rarity as keyof typeof source][language as keyof typeof Rarity]}
    />
  );
}
