import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

type Rule = {
  valid: boolean;
  message: string;
};

type RuleNames = "length" | "special" | "number" | "lowercase" | "uppercase";

function Validate(value: string) {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(false);

  const rules: { [key in RuleNames]: Rule } = useMemo(() => {
    return {
      length: {
        valid: value.length >= 8,
        message: t("global.password.validation.length")
      },
      special: {
        valid: /[^A-Z\d]/gi.test(value),
        message: t("global.password.validation.special")
      },
      number: {
        valid: /\d/.test(value),
        message: t("global.password.validation.number")
      },
      lowercase: {
        valid: /[a-z]/.test(value),
        message: t("global.password.validation.lowercase")
      },
      uppercase: {
        valid: /[A-Z]/.test(value),
        message: t("global.password.validation.uppercase")
      }
    };
  }, [value, t]);

  useEffect(() => {
    const ruleTypes: [RuleNames, RuleNames, RuleNames, RuleNames, RuleNames] = ["length", "special", "number", "lowercase", "uppercase"];

    if (ruleTypes.every((type) => rules[type].valid)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [rules]);

  return { rules, isValid };
}

function PasswordValidation({ password }: { password: string }) {
  return (
    <ul className="mt-1 grid grid-cols-1 text-xs text-black md:grid-cols-2">
      <li className="flex items-center">
        <i className={Validate(password).rules.uppercase.valid ? "icon-check text-green-600" : "icon-exclamation"} />
        <p className="ml-1">{Validate(password).rules.uppercase.message}</p>
      </li>
      <li className="flex items-center">
        <i className={Validate(password).rules.special.valid ? "icon-check text-green-600" : "icon-exclamation"} />
        <p className="ml-1">{Validate(password).rules.special.message}</p>
      </li>
      <li className="flex items-center">
        <i className={Validate(password).rules.lowercase.valid ? "icon-check text-green-600" : "icon-exclamation"} />
        <p className="ml-1">{Validate(password).rules.lowercase.message}</p>
      </li>
      <li className="flex items-center">
        <i className={Validate(password).rules.length.valid ? "icon-check text-green-600" : "icon-exclamation"} />
        <p className="ml-1">{Validate(password).rules.length.message}</p>
      </li>
      <li className="flex items-center">
        <i className={Validate(password).rules.number.valid ? "icon-check text-green-600" : "icon-exclamation"} />
        <p className="ml-1">{Validate(password).rules.number.message}</p>
      </li>
    </ul>
  );
}

export { PasswordValidation, Validate };
