import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppContext } from "context/AppContext";
import { useFormFields } from "libs/fields";
import { onError } from "libs/error";
import { Auth } from "aws-amplify";
import { SubmitButton, TextInput, ClickButton } from "components/FormElements";
import Header from "components/Header";
import Footer from "components/Footer";
import Modal from "components/Modal";

export default function VerifyEmail() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const { setUser } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [fields, handleFieldChange] = useFormFields({
    code: ""
  });

  useEffect(() => {
    document.title = t("local.account.changeemail.title.page") + " - " + t("global.system.name");
    if (process.env.REACT_APP_VERSION !== "OFFICIAL") {
      document.title += " | " + process.env.REACT_APP_VERSION;
    }
  });

  function validateForm() {
    return fields.code.length === 6;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", fields.code);
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true
      });
      setUser(user);
      setIsLoading(false);
      setShowModal(true);
    } catch (e) {
      const errorHandler = onError(e);
      setErrorMessage(errorHandler);
      setIsLoading(false);
    }
  }

  async function resendConfirmationCode(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.resendSignUp(location.state.username);
      setIsLoading(false);
    } catch (e) {
      const errorHandler = onError(e);
      setErrorMessage(errorHandler);
      setIsLoading(false);
    }
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow bg-ct-lightgrey px-4 md:px-0">
        <h2 className="text-3xl text-black text-center pt-20 mb-2.5">{t("local.account.changepass.title.verifyemail")}</h2>
        <p className="text-base text-black text-center mb-6">{t("local.account.subtitle.entercode")}</p>
        <form className="container mx-auto max-w-md flex flex-col justify-center space-y-4 pb-20" onSubmit={handleSubmit}>
          <TextInput handleChange={handleFieldChange} name="code" value={fields.code} label={t("global.label.confirmationcode")} />
          <SubmitButton disabled={!validateForm() || isLoading} loading={isLoading} label={t("global.button.completeverification")} />
          <p className="boutton bg-gray-100 inline-flex text-sm border border-ct-grey text-gray-800 px-4 py-3">
            <i className="icon-drawer"></i>
            <span className="ml-2">{t("global.label.checkemailforcode")}</span>
          </p>
          <ClickButton handleClick={resendConfirmationCode} disabled={isLoading} loading={isLoading} label={t("global.button.sendnewcode")} />
          {errorMessage}
        </form>
      </main>
      <Footer />
      {showModal && (
        <Modal
          title={t("local.account.changeemail.label.emailsuccess")}
          subtitle={t("local.account.changeemail.title.success")}
          handleClick={() => navigate("/account")}
          clickLabel={t("global.button.continue")}
        />
      )}
    </div>
  );
}
