import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ClickButton } from "components/FormElements";
import Header from "components/Header";
import Footer from "components/Footer";

export default function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const redirect = () => {
    navigate("/");
  };

  useEffect(() => {
    document.title = t("local.login.title.page") + " - " + t("global.system.name");
    if (process.env.REACT_APP_VERSION !== "OFFICIAL") {
      document.title += " | " + process.env.REACT_APP_VERSION;
    }
  }, [t]);

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow bg-ct-lightgrey px-4 md:px-0">
        <h2 className="text-3xl text-black text-center mt-20 mb-2.5">{t("local.404.title.main")}</h2>
        <section className="container max-w-lg flex flex-col mx-auto mb-20">
          <p className="text-base text-black mb-6">{t("local.404.content.main")}</p>
          <ClickButton
            handleClick={redirect}
            id="home"
            label={t("global.link.returntoredeem")}
            colour="bg-ct-orange"
            disabled={false}
            loading={false}
          />
        </section>
      </main>
      <Footer />
    </div>
  );
}
