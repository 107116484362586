import logoBookchainPwdby_en from "images/en/logo-bookchain-pwdby.png";
import logoBookchainPwdby_fr from "images/fr/logo-bookchain-pwdby.png";
import logoCompanyHeader_en from "images/comictags-logo-black-beta.png";
import logoCompanyHeader_fr from "images/comictags-logo-black-beta.png";

type LogoType = {
  en: string;
  fr: string;
};

type Source = {
  PoweredByBookchain: LogoType;
  CompanyHeader: LogoType;
};

export default function Logo({ logo, language, imgClass, altText }: { logo: string; language: string; imgClass: string; altText: string }) {
  const source: Source = {
    PoweredByBookchain: {
      en: logoBookchainPwdby_en,
      fr: logoBookchainPwdby_fr
    },
    CompanyHeader: {
      en: logoCompanyHeader_en,
      fr: logoCompanyHeader_fr
    }
  };

  return <img className={imgClass} src={source[logo as keyof typeof source][language as keyof typeof Logo]} alt={altText} />;
}
