import { config } from "config";
import HttpRequest from "./HttpRequest";

export default class HttpDataSource {
  private size: number;
  private username: string;
  private fileName: string;
  url: string;
  constructor(username: string, fileName: string, size: number) {
    this.url = "https://s3.amazonaws.com/" + config.s3.BUCKET + "/protected/" + username + "/" + fileName.replace(/[^\w\-.]/, "+");
    this.size = size;
    this.username = username;
    this.fileName = fileName;
  }

  /**
   * Fetch a chunk of data from the data source between a start and end offset.
   * This method must not throw an exception in case of a non-recoverable error.
   *
   * @param startOffset - An index into the data source indicating the first byte to include in the result buffer
   * @param endOffset - An index into the data source indicating the first byte that will *not* be included in the result buffer.
   *                    Must be larger than 'start', and less than or equal to the value returned by getSize().
   * @return Resolved with an ArrayBuffer containing the requested data. Rejected if it is not possible to fetch the requested data.
   */

  async fetchChunk(startOffset: number, endOffset: number) {
    return await HttpRequest.sendRangeRequest(this.username, this.fileName, startOffset, endOffset - 1);
  }

  /**
   * Get the size of the data in bytes.
   */
  getSize(): number {
    return this.size;
  }
}
