import { useContext, createContext } from "react";
import { CognitoUserSession, CognitoUser } from "amazon-cognito-identity-js";

interface AppContextInterface {
  isAuthenticated: boolean;
  userHasAuthenticated: (value: boolean) => void;
  user: CognitoUser | null;
  setUser: (value: CognitoUser | null) => void;
  session: CognitoUserSession | null;
  setSession: (value: CognitoUserSession | null) => void;
}

export const AppContext = createContext<Partial<AppContextInterface>>({});

export function useAppContext() {
  return useContext(AppContext);
}
